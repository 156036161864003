import { Container } from '../../components/Container'
import { Box, BoxProps, Flex, Grid, Image, Stack } from '@chakra-ui/react'
import Logo from './images/logo.svg'
import { DocPrivacyPolicyModal } from 'shared/modals/DocPrivacyPolicyModal'
import { DocOfferModal } from 'shared/modals/DocOfferModal'
import React, { ReactNode, useState } from 'react'
import { DocCookiesModal } from 'shared/modals/DocCookiesModal'
import { DocRequisitesModal } from 'shared/modals/DocRequisitesModal'
import { docLinks } from '../../../../../../constants/links'
import { useNavigate } from 'react-router-dom'

export const Footer = () => {
  const navigate = useNavigate()
  const [isDocPrivacyPolicyOpen, setIsDocPrivacyPolicyOpen] = useState(false)
  const [isDocOfferOpen, setIsDocOfferOpen] = useState(false)
  const [isDocCookiesOpen, setIsDocDocCookiesOpen] = useState(false)
  const [isDocRequisitesOpen, setIsDocRequisitesOpen] = useState(false)

  return (
    <>
      <Container pt="40px">
        <Flex
          width="100%"
          justifyContent="center"
          background="linear-gradient(177deg, #1D2552 2.22%, #374396 97.56%)"
          padding="20px"
          borderTopLeftRadius="24px"
          borderTopRightRadius="24px"
        >
          <Flex gap="20px" flexDirection="column" maxW="920px" width="100%">
            <Grid templateColumns={['1fr', '1fr', '180px 1fr 1fr']} gap="16px">
              <Flex>
                <Image maxW="120px" src={Logo} alt="Logo" />
              </Flex>
              <Stack spacing="6px" lineHeight="1.2" pt="20px">
                <DocLink onClick={() => window.open(docLinks.privacyPolicy)}>Политика конфиденциальности</DocLink>
                <DocLink onClick={() => window.open(docLinks.offer)}>Оферта на оказание услуг</DocLink>
                <DocLink onClick={() => window.open(docLinks.cookies)}>Политика использования файлов cookie</DocLink>
                <DocLink onClick={() => setIsDocRequisitesOpen(true)}>Реквизиты</DocLink>
                <DocLink onClick={() => navigate('/legal')}>Документы</DocLink>
              </Stack>
              {/*<Flex justifyContent={['flex-start', 'flex-start', 'flex-end']} pt="20px">
                <Button
                  colorScheme="whiteAlpha"
                  backgroundColor="rgba(255, 255, 255, 0.04)"
                  leftIcon={<Image src={Telegram} alt="Telegram" boxSize="24px" />}
                  _hover={{
                    backgroundColor: 'rgba(255, 255, 255, 0.1)'
                  }}
                  _active={{
                    backgroundColor: 'rgba(255, 255, 255, 0.04)'
                  }}
                >
                  Написать в Телеграм
                </Button>
              </Flex>*/}
            </Grid>
            <Box fontSize="0.8rem" opacity={0.5} color="white">© 2024,  Alfred Market</Box>
          </Flex>
        </Flex>
      </Container>
      <DocPrivacyPolicyModal
        isOpen={isDocPrivacyPolicyOpen}
        onClose={() => setIsDocPrivacyPolicyOpen(false)}
      />
      <DocOfferModal
        isOpen={isDocOfferOpen}
        onClose={() => setIsDocOfferOpen(false)}
      />
      <DocCookiesModal
        isOpen={isDocCookiesOpen}
        onClose={() => setIsDocDocCookiesOpen(false)}
      />
      <DocRequisitesModal
        isOpen={isDocRequisitesOpen}
        onClose={() => setIsDocRequisitesOpen(false)}
      />
    </>
  )
}

interface DocLinkProps extends BoxProps {
  children: ReactNode
}

const DocLink: React.FC<DocLinkProps> = ({ children, ...props }) => {
  return (
    <Box
      color="white"
      opacity={0.7}
      transition="0.3s"
      cursor="pointer"
      fontSize="0.94rem"
      _hover={{
        opacity: 1
      }}
      {...props}
    >
      {children}
    </Box>
  )
}
